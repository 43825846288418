exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cash-balance-calc-js": () => import("./../../../src/pages/cash-balance-calc.js" /* webpackChunkName: "component---src-pages-cash-balance-calc-js" */),
  "component---src-pages-meet-our-team-js": () => import("./../../../src/pages/meet-our-team.js" /* webpackChunkName: "component---src-pages-meet-our-team-js" */),
  "component---src-pages-pension-risk-transfer-calc-js": () => import("./../../../src/pages/pension-risk-transfer-calc.js" /* webpackChunkName: "component---src-pages-pension-risk-transfer-calc-js" */),
  "component---src-pages-team-contentful-marketing-bio-slug-js": () => import("./../../../src/pages/team/{contentfulMarketingBio.slug}.js" /* webpackChunkName: "component---src-pages-team-contentful-marketing-bio-slug-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-contentful-article-js": () => import("./../../../src/templates/contentful-article.js" /* webpackChunkName: "component---src-templates-contentful-article-js" */),
  "component---src-templates-contentful-articles-collection-js": () => import("./../../../src/templates/contentful-articles-collection.js" /* webpackChunkName: "component---src-templates-contentful-articles-collection-js" */),
  "component---src-templates-contentful-articles-topic-collection-js": () => import("./../../../src/templates/contentful-articles-topic-collection.js" /* webpackChunkName: "component---src-templates-contentful-articles-topic-collection-js" */),
  "component---src-templates-contentful-marketing-page-js": () => import("./../../../src/templates/contentful-marketing-page.js" /* webpackChunkName: "component---src-templates-contentful-marketing-page-js" */),
  "component---src-templates-contentful-service-detail-page-js": () => import("./../../../src/templates/contentful-service-detail-page.js" /* webpackChunkName: "component---src-templates-contentful-service-detail-page-js" */),
  "component---src-templates-contentful-services-category-page-js": () => import("./../../../src/templates/contentful-services-category-page.js" /* webpackChunkName: "component---src-templates-contentful-services-category-page-js" */)
}

